<template>
  <div>
    
    <!--  -->
    <div class="landing section">
      <!-- <b-navbar toggleable="lg" class="nav-ctm">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="mx-auto mt-3">
            <b-navbar-brand href="/" class="mr-5"><img src="../assets/logo1x1.png" style="width: 40px"></b-navbar-brand>
            <b-nav-item href="/info">Party Revive</b-nav-item>
            <b-nav-item href="/tickets">Tickets</b-nav-item>
            <b-nav-item href="/fotos">Fotos</b-nav-item>
            <b-nav-item href="/contacto">Contacto</b-nav-item>
            <b-nav-item href="/login">Mi cuenta</b-nav-item>
            <b-navbar-brand href="/"><img src="../assets/logo1x1.png" style="width: 40px"></b-navbar-brand>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar> -->
      <div class="text-ctn">
        <b-row>
          <b-col sm="12" md="12" class="my-auto">
            <div v-if="$store.state.isPartyRevive">
              <h1>PARTY <span class="green">RE</span><span class="blue">VI</span><span class="red">VE</span></h1>
              <h4>ERES TÚ Y LO COMPONEMOS TODOS</h4>
              <!-- <img class="cartel d-block-xs" style="margin: auto" src="../assets/pop-up-market-3-4-dic.jpg"/> -->
              <!-- <h4>¡SOLD OUT EN 30 MINUTOS!<br>¡MUCHAS GRACIAS A TODOS!</h4> -->
              <!-- <b-btn variant="info" class="mt-4" :href="getLink">COMPRAR ENTRADA MIKE MORATO (30.5€)</b-btn> -->
              <!-- <b-btn variant="info" class="mt-4" href="https://buy.stripe.com/28o1497iUcn8f5ebJq">COMPRAR ENTRADA POP UP MARKET (2.5€) </b-btn> -->
              <br>
              <!-- <span class="text-small">*Entradas nominativas. Sólo válida una entrada por persona.<br>¡IMPORTANTE! Introducir nombre y apellidos completos en el campo de 'Nombre en la tarjeta'. </span> -->
              <!-- <b-btn variant="info" class="mt-4 mb-5" href="https://buy.stripe.com/5kAbINeLm86S7CM14h">COMPRAR BONOCOPAS (4 COPAS x 20.5€)</b-btn> -->
              <!-- <b-btn variant="info" class="mt-4" href="https://buy.stripe.com/9AQ3ch8mY5YKg9i9AJ">COMPRAR ENTRADA + COPA 12.5€</b-btn> -->
              <p class="mt-5">Estamos organizando el próximo evento...</p>
            </div>
            <div v-if="$store.state.isBuvac">
              <img src="https://i.ibb.co/MCqSGyv/logo-buvac.png"/>
              <p class="mt-5">Estamos organizando el próximo evento...</p>
            </div>
            <div v-if="$store.state.isTheBoss">
              <img src="https://i.ibb.co/7rJ5VNd/theboss-logo-negro.png"/>
              <p class="mt-5">Estamos organizando el próximo evento...</p>
            </div>
          </b-col>
          <!-- <b-col sm="12" md="6" class="d-block-md">
            <img class="cartel" src="../assets/pop-up-market-3-4-dic.jpg"/>
          </b-col> -->
        </b-row>
        
      </div>
    </div>
  </div>
</template>

<script>
import rrpps from '@/assets/rrpps.json'

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    getLink () {
      return this.getRefLink || 'https://buy.stripe.com/5kA28d0Uw2My1eo3cO'
    },
    getRefLink () {
      return rrpps[this.$route.query.ref]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../assets/scss/global.scss';


.d-block-xs {
  @include mobile {
    display:block;
  }
  @include media(desktop, tablets){
    display: none;
  }
}

.d-block-md {
  @include mobile {
    display:none;
  }
  @include media(desktop, tablets){
    display: block;
  }
}
/* .navbar-nav{
    margin: 0 auto;
    display: table;
    table-layout: fixed;
    float:none;
} */

.nav-ctm {
  background-color: transparent;

  .nav-item {
    text-transform: uppercase;
    font-weight: bold;
    &:not(:last-child) {
      margin-right: 2rem;
    }
    a {
      color: white !important;
      &:hover {
        transition: .5s all ease-in;
        color: $red !important;
      }
    }

    &:hover {
        transition: .5s all ease-in;
        color: $red;
        padding-bottom: 5px;
        border-bottom: 1px solid $red;
      }
  }
}

.section {
    height: 100vh;
  }

.landing {
  height: 100vh;
  text-align: center;
  .text-ctn {
    display:flex;
    align-items: center;
    justify-content: center;

    @include media(desktop, tablets) {
      height: 80vh;
    }
    @include mobile {
      padding-top: 6rem;
      padding: {
        right: 1rem;
        left: 1rem;
      }
    }

    .cartel {
      width: 70%;
      box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
    }

    h1 {
      color: white;
      @include media(desktop, tablets) {
        font-size: 5rem;
      }
      @include mobile {
        font-size: 3rem;
      }
      margin-bottom: 2rem;
      text-shadow: 0px 0px 6px rgba(255,255,255,0.7);
    }

    h4 {
      color: #ffffffb2;
      margin-bottom: 2rem;
    }
  }
  
}
</style>
